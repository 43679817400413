import React from "react"

import { ErrorMessage } from "@hookform/error-message"
import MuiCheckbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import { Controller } from "react-hook-form"

interface CheckboxProps {
  control: any // eslint-disable-line @typescript-eslint/no-explicit-any
  errors: any // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string

  // NOT REQUIRED
  defaultValue?: boolean
  disabled?: boolean
  indeterminate?: boolean
  label?: string
  loading?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  sxLabel?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  dataCy?: string
}

const Checkbox: React.FC<CheckboxProps> = ({
  control,
  defaultValue = false,
  disabled,
  errors = [],
  indeterminate,
  label,
  name,
  onChange,
  required,
  sx,
  sxLabel,
  dataCy,
}: CheckboxProps) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      data-cy={dataCy}
      render={({ field }) => (
        <>
          <FormControlLabel
            control={
              <MuiCheckbox
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                checked={field.value}
                indeterminate={indeterminate}
                onChange={onChange ?? field.onChange}
                required={required}
                sx={sx}
                disabled={disabled}
              />
            }
            label={label}
            sx={sxLabel}
          />

          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <FormHelperText error>{message}</FormHelperText>
            )}
          />
        </>
      )}
    />
  )
}

export default Checkbox
