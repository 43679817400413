import { lazzy } from "@services/lazy"

const views = {
  // Auth Views
  Home: lazzy("Home", () => import("@views/auth/Home")),
  SignIn: lazzy("SignIn", () => import("@views/auth/SignIn")),
  SignUp: lazzy("SignUp", () => import("@views/auth/SignUp")),
  ProfileBuilder: lazzy(
    "ProfileBuilder",
    () => import("@views/auth/ProfileBuilder"),
  ),

  // Sales Hub
  Orders: lazzy("Orders", () => import("@views/sales/Orders/index")),
  Quotes: lazzy("Quotes", () => import("@views/sales/Quotes/index")),

  // People Hub
  Users: lazzy("Users", () => import("@views/people/Users")),
  UserDetail: lazzy("User Detail", () => import("@views/people/UserDetail")),
  Companies: lazzy("Companies", () => import("@views/people/Companies")),
  Contacts: lazzy("Contacts", () => import("@views/people/Contacts")),

  // Finance Hub
  PurchaseOrders: lazzy(
    "PurchaseOrders",
    () => import("@views/finance/PurchaseOrders"),
  ),

  // Operations Hub
  Items: lazzy("Items", () => import("@views/operations/Items")),
  Production: lazzy("Production", () => import("@views/operations/Production")),
  Receiving: lazzy("Receiving", () => import("@views/operations/Receiving")),
  Inventory: lazzy("Inventory", () => import("@views/operations/Inventory")),
  Shipping: lazzy("Shipping", () => import("@views/operations/Shipping")),

  // Reporting Hub
  Reports: lazzy("Shipping", () => import("@views/reporting/Reports")),

  // User Views
  Dashboard: lazzy("Dashboard", () => import("@views/Dashboard")),
  CustomDocs: lazzy(
    "CustomDocs",
    () => import("@views/organizationSettings/CustomDocs"),
  ),
  CustomFields: lazzy(
    "CustomFields",
    () => import("@views/organizationSettings/CustomFields"),
  ),
  CustomForms: lazzy(
    "CustomForms",
    () => import("@views/organizationSettings/CustomForms"),
  ),
  CustomLabels: lazzy(
    "CustomLabels",
    () => import("@views/organizationSettings/CustomLabels"),
  ),
  Integrations: lazzy(
    "Integrations",
    () => import("@views/organizationSettings/Integrations"),
  ),
  Operations: lazzy(
    "Operations",
    () => import("@views/organizationSettings/Operations"),
  ),
  RolesPermissions: lazzy(
    "RolesPermissions",
    () => import("@views/organizationSettings/PermissionsBilling"),
  ),
  Settings: lazzy(
    "Settings",
    () => import("@views/organizationSettings/Settings"),
  ),
  Tools: lazzy("Tools", () => import("@views/organizationSettings/Tools")),
  WarehouseLocations: lazzy(
    "WarehouseLocations",
    () => import("@views/organizationSettings/WarehouseLocations"),
  ),
  Equipment: lazzy(
    "Equipment",
    () => import("@views/organizationSettings/Equipment"),
  ),

  NotAuthorized: lazzy(
    "NotAuthorized",
    () => import("@views/auth/NotAuthorized"),
  ),
  NoOrganization: lazzy(
    "NoOrganization",
    () => import("@views/auth/NoOrganization"),
  ),
  NotFound: lazzy("NotFound", () => import("@views/auth/NotFound")),
}

export default views
